.nav {
    border-right: 1px solid #51515B;
    padding: 15px;
    padding-left: 30px;
    padding-right: 30px;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.nav-item {
    font-size: xx-large;
    font-weight: 700;
    color: #ABABBF;
    text-decoration: none;
    margin-top: 10px;
}

.nav-item:hover {
    color: #7560F5;
}

.nav-item--active {
    color: #7560F5;
}

.nav-item-container {
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    transition: 0.5s;
}

.nav-item-container:hover {
    background-color: #7460f531;
}

.nav-item-text {
    margin-left: 15px;
    font-size: x-large;
}

.react-icons {
    size: 10px;
}

img {
    width: 150px;
    padding-top: 5px;
    padding-bottom: 30px;
    padding-left: 10px;
    padding-right: 20px;
}