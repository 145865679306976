.sign-in-button {
    padding: 20px;
    color: #7560F5;
    border: 3px solid #7560F5;
    background-color: #111827;
    border-radius: 10px;
    font-weight: 700;
    font-size: large;
    width: 100%;
}

.sign-in-button:hover {
    background-color: #2c2652;
}

.sign-in-form {
    width: 300px;
    margin: auto;
    margin-top: 35vh;
}

.sign-in-logo {
    display: block;
    margin: auto;
    padding-right: 10px;
    width: 60%;
    margin-bottom: 10px;
}