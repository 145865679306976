.sign-out-button {
    padding: 7px;
    padding-left: 15px;
    padding-right: 15px;
    color: #646475;
    border: 2px solid #646475;
    background-color: #272737;
    border-radius: 10px;
    font-weight: 700;
    font-size: medium;
    transition: 0.3s;
}

.sign-out-button:hover {
    background-color: #404058;
}