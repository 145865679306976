.tweet {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 30px;
    padding-left: 30px;
    background-color: #272737;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    font-size: medium;
    display: block;
}

.delete-button {
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 10px;
    color: #8a8a99;
    border: 2px solid #646475;
    background-color: #272737;
    border-radius: 10px;
    font-weight: 700;
    font-size: medium;
    transition: 0.3s;
}

.delete-button:hover {
    background-color: #404058;
}

.share-button {
    margin-left: 5px;
    padding: 7px;
    padding-left: 15px;
    padding-right: 15px;
    color: #7560F5;
    border: 2px solid #7560F5;
    background-color: #272737;
    border-radius: 10px;
    font-weight: 700;
    font-size: medium;
}

.share-button:hover {
    background-color: #39345c;
}

.tweet-buttons {
    display: flex;
    float: right;
}

.tweet-button-container {
    margin-bottom: 15px;
    background-color: #212131;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 30px;
    padding-left: 30px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border: 1px solid #272737;
    font-size: large;
    display: block;
}

.tweet-text {
    color: #c3c3d3;
    white-space: pre-line;
}

.queue-page {
    display: flex;
    height: 100vh;
}

.queue {
    padding: 15px;
    padding-bottom: 100px;
    overflow-y: scroll;
}

.paused-alert {
    padding: 15px;
    padding-left: 20px;
    background-color: #39345c;
    margin-bottom: 15px;
    border-radius: 15px;
    border: 3px solid #534a91;
    color: #c8c1f3;
}

.schedule {
    padding: 15px;
    min-width: 300px;
}

.schedule-card {
    background-color: #272737;
    padding: 20px;
    padding-top: 30px;
    border-radius: 20px;
}

.upgrade-link {
    color: #7460f5c9;
    transition: 0.3s;
}

.upgrade-link:hover {
    color: #7560F5;
}

.pause-tweets-container {
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}

.pause-tweet-toggle {
    margin-left: auto;
}

h1 {
    margin: 0px;
    border-bottom: 1px solid #51515B;
    padding: 15px;
    padding-left: 20px;
}

h2 {
    font-size: xx-large;
    color: #E5E5E5;
}
h3 {
    font-weight: 200;
    color: #ABABBF;
}