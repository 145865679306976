.card {
    background-color: #272737;
    padding: 40px;
    border-radius: 20px;
    margin-bottom: 12px;
}

.account-page {
    width: 100%;
}

.upgrade-button {
    padding: 7px;
    padding-left: 15px;
    padding-right: 15px;
    color: #7560F5;
    border: 2px solid #7560F5;
    background-color: #272737;
    border-radius: 10px;
    font-weight: 700;
    font-size: medium;
    transition: 0.3s;
}

.upgrade-button:hover {
    background-color: #39345c;
}

h2 {
    margin: 0px;
    padding: 0px;
}